import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const displayLimit = 5

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem auto;
  max-width: 20rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    margin: 2rem auto 0;
  }
  a {
    display: flex;
    flex-flow: column;
    color: ${props => props.theme.colors.green};
    font-size: 1.5rem;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      /* background: ${props => props.theme.colors.highlight}; */
    }
    @media screen and (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 14px;
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 4rem auto;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    margin-bottom: 1rem;
    padding-left: initial;
  }
`

const Item = styled.li`
  display: flex;
  flex-flow: row;
  color: ${props => props.theme.colors.text};
  font-size: 1.5rem;
  line-height: 1.25em;
  text-decoration: none;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 14px;
    line-height: 1.25rem;
  }
`

const PreviousLink = styled(Link)`
  margin-right: auto;
  order: 1;
`

const NextLink = styled(Link)`
  margin-left: auto;
  order: 2;
`

const YearlyNav = ({ link, allYears, yearIdx }) => {
  const budget = Math.floor(displayLimit / 2)
  const size = allYears.length

  // best effort to display `displayLimit` years as possible
  let start = Math.max(yearIdx - budget, 0)
  let end = Math.min(yearIdx + budget, size - 1)
  if (yearIdx - start < budget) {
    let diff = budget - (yearIdx - start)
    end = Math.min(end, end + diff)
  } else if (end - yearIdx < budget) {
    let diff = budget - (end - yearIdx)
    start = Math.max(start, start - diff)
  }

  let displayIdx = []
  for (let i = start; i <= end; i++) {
      displayIdx.push(i)
  }

  return (
    <ListContainer>
      <List>
        <Item>
          {(yearIdx - 1 >= 0) 
            ? <PreviousLink to={`${link}/${allYears[yearIdx-1]}`} state={{ yearIdx: yearIdx-1 }}>&#60;</PreviousLink>
            : <div></div>
          }
        </Item>
        {displayIdx.map((idx) => (
          <Item key={idx}>
            {(idx === yearIdx)
              ? <div>{allYears[yearIdx]}</div>
              : <Link to={`${link}/${allYears[idx]}`} state={{ yearIdx: idx }}>{allYears[idx]}</Link>
            }
          </Item>
        ))}
        <Item>
          {(yearIdx + 1 < size) 
            ? <NextLink to={`${link}/${allYears[yearIdx+1]}`} state={{ yearIdx: yearIdx + 1 }}>&#62;</NextLink>
            : <div></div>
          }
        </Item>
      </List>
    </ListContainer>
  )
}

export default YearlyNav
