import React from 'react'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import { startCase } from 'lodash'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Header from '../components/SermonPage/Header'
import BreadCrumb from '../components/SermonPage/BreadCrumb'
import YearlyNav from '../components/SermonPage/YearlyNav'
import { SUNDAY_SERVICE_BASE, MONTHLY_BASE, YEARLY_BASE } from '../routes'
import { SUNDAY_SERVICE_TITLE, WEEKDAY_SERVICE_TITLE, MONTHLY_TITLE, MONTH_NAMES } from '../constants'

const MonthRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 75rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    flex-direction: row;
    margin-top: -1rem;
    flex-wrap: initial;
  }
`

const MonthColumn = styled.div`
  display: flex; 
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 2rem auto;
  max-width: 65rem;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    margin: 2rem auto 0;
  }
  a {
    display: flex;
    flex-flow: column;
    color: ${props => props.theme.colors.green};
    font-family: "Apple SD Gothic Neo";
    font-size: 2.5em;
    text-align: center;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      /* background: ${props => props.theme.colors.highlight}; */
    }
    @media screen and (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 14px;
    }
  }
`

const Month = styled.div`
  margin: 6rem;
  font-size: 1rem;
  text-align: center;
  word-break: keep-all;
  color: ${props => props.theme.colors.text};
`

const MonthName = styled.div`
  color: ${props => props.theme.colors.green};
  font-family: "Apple SD Gothic Neo";
  font-size: 2.5em;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 14px;
  }
`

const Count = styled.div`
  font-size: 1em;
  margin-top: 0.5rem;
  color: ${props => props.theme.colors.textGrey};
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 14px;
  }
`

function aggregateCountByMonth(sermons) {
  let months = new Array(12).fill(0)
  sermons.map(({ node: sermon }) => {
    let idx = parseInt(sermon.dateSlug.slice(5, 7)) - 1
    months[idx]++
  })
  return months
}

function formatMonthlyCount(basePath, currYear, counts, idx) {
  let month = (idx < 9) ? `0${idx+1}` : `${idx+1}`
  let yyyyMM = `${currYear}-${month}`
  let link = (counts[idx] > 0) 
    ? <Link to={`${basePath}/${MONTHLY_BASE}/${yyyyMM}`}>{MONTH_NAMES[idx]}</Link>
    : <MonthName>{MONTH_NAMES[idx]}</MonthName>

  return (
    <Month>
      {link}
      <Count>{`${counts[idx]} Sermons`}</Count>
    </Month>
  )
}

const Yearly = ({ location, data, pageContext }) => {
  const { basePath, headerTitle, allYears, currYear } = pageContext
  const title = (basePath === SUNDAY_SERVICE_BASE) ? SUNDAY_SERVICE_TITLE : WEEKDAY_SERVICE_TITLE
  const sermons = (basePath === SUNDAY_SERVICE_BASE) ? data.allContentfulSermonSunday.edges : data.allContentfulSermonWeekday.edges
  const counts = aggregateCountByMonth(sermons)
  const yearIdx = (!location.state || location.state.yearIdx === undefined) ? allYears.length - 1 : location.state.yearIdx
  const routes = [{ slug: basePath, label: title }, { slug: `${basePath}/${YEARLY_BASE}/`, label: MONTHLY_TITLE}]

  return (
    <Layout>
      <SEO title={startCase(basePath)} />
      <Header title={headerTitle} height={'20vh'} />
      <Container>
        <BreadCrumb routes={routes} />
        <YearlyNav link={`${basePath}/${YEARLY_BASE}`} allYears={allYears} yearIdx={yearIdx} />
        <MonthRow>
          <MonthColumn>
            {[0, 3, 6, 9].map((idx) => formatMonthlyCount(basePath, currYear, counts, idx))}
          </MonthColumn>
          <MonthColumn>
            {[1, 4, 7, 10].map((idx) => formatMonthlyCount(basePath, currYear, counts, idx))}
          </MonthColumn>
          <MonthColumn>
            {[2, 5, 8, 11].map((idx) => formatMonthlyCount(basePath, currYear, counts, idx))}
          </MonthColumn>
        </MonthRow>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($currYear: Date!, $endYear: Date!) {
    allContentfulSermonSunday(filter: { date: { gt: $currYear, lt: $endYear, ne: null } }) {
      edges {
        node {
          dateSlug: date(formatString: "YYYY-MM-DD")
        }
      }
    }
    allContentfulSermonWeekday(filter: { date: { gt: $currYear, lt: $endYear, ne: null } }) {
      edges {
        node {
          dateSlug: date(formatString: "YYYY-MM-DD")
        }
      }
    }
  }
`

export default Yearly
